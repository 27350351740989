function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}
function setCookie(name, value, options = {}) {
    options = {
        path: '/',
        // при необходимости добавьте другие значения по умолчанию
        ...options
    };

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }
    document.cookie = updatedCookie;
}
export default{
    state: () => ({
        likes: [],
    }),
    mutations: {
        INIT_FAVORITES: (state) => {
            // let likes = JSON.parse(localStorage.getItem("likes" + state.id));
            state.likes = []
            if(getCookie("likes" + state.id)){
                let likes = JSON.parse(getCookie("likes" + state.id))
                if (likes) {
                    state.likes = state.likes.concat(likes);
                }
            }
        },
        SET_FAVORITES:(state,data) => {
            state.likes = data
        },
        SET_LIKE_TO_STATE: (state, data) => {
            if (state.likes.includes(data)) {
                state.likes = state.likes.filter((like) => like !== data);
                setCookie("likes" + state.id, JSON.stringify(state.likes), {secure: true, 'max-age': 315360000});
                // localStorage.setItem("likes" + state.id, JSON.stringify(state.likes));
            } else {
                state.likes.push(data);
                setCookie("likes" + state.id, JSON.stringify(state.likes), {secure: true, 'max-age': 315360000});
                // localStorage.setItem("likes" + state.id, JSON.stringify(state.likes));
            }
        },
    },
    actions: {
        TOGGLE_LIKE(ctx, id) {
            ctx.commit("SET_LIKE_TO_STATE", id);
        },
        PUSH_FAVORITES_FROM_PARAMS(ctx,value){
            ctx.commit('SET_FAVORITES',value)
        },
    },
    getters: {
        isLiked: (state) => (id) => {
            return state.likes?.find((like) => like == id);
        },
        favorites(state) {
            return state.likes;
        },
    }
}