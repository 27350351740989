import notFoundImage from "@/assets/images/blank-image.svg";

export default {
  inserted: el => {
    const imageElement = el.querySelector("img");
    const debounceLoadImage = debounce(loadImage, 300);

    function loadImage() {
      imageElement.addEventListener(
          "load",
          () => {
            el.classList.add("loaded");
          },
          { once: true }
      );
      imageElement.addEventListener(
          "error",
          () => {
            el.classList.add("loaded");
            imageElement.src = notFoundImage;
          },
          { once: true }
      );
      imageElement.src = imageElement.dataset.url;
    }

    function handleIntersect(entries, observer) {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return;
        }
        debounceLoadImage();
        observer.unobserve(el);
      });
    }
    function createObserver(){
      const observer = new IntersectionObserver(handleIntersect, {
        root: null,
        threshold: 0,
      });
      observer.observe(el);
    }



    if (!window["IntersectionObserver"]) {
      debounceLoadImage();
    } else {
      createObserver();
    }

  },
};

function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}