import Vue from "vue";
import store from "@/store";
import VueRouter from "vue-router";
const PageInteractive = () =>
  import(/* webpackChunkName: "Interactive" */ "@/views/PageInteractive");
const PagePremises = () =>
  import(/* webpackChunkName: "Premises" */ "@/views/PagePremises");
const PageChess = () =>
  import(/* webpackChunkName: "Chess" */ "@/views/PageChess");
const PageInfo = () =>
  import(/* webpackChunkName: "Info" */ "@/views/PageInfo");
const PageProjects = () =>
  import(/* webpackChunkName: "Info" */ "@/views/PageProjects");
const PagePanorama = () =>
  import(/* webpackChunkName: "Panorama" */ "@/views/PagePanorama");
const Main = () => import(/* webpackChunkName: "Main" */ "@/components/Main");
const Favorites = () =>
  import(/* webpackChunkName: "Favorites" */ "@/views/Favorites");
const PageNotFound = () =>
  import(/* webpackChunkName: "404" */ "@/components/PageNotFound");

const Pdf = () => import(/* webpackChunkName: "PDF" */ "@/components/Pdf");
const Instructions = () =>
  import(/* webpackChunkName: "Instructions" */ "@/views/Instructions");
const AboutView = () =>
  import(/* webpackChunkName: "about" */ "../views/AboutView.vue");
Vue.use(VueRouter);

const routes = [
  {
    path: "/error-404",
    name: "error-404",
    component: PageNotFound,
  },
  { path: "*", redirect: "error-404" },
  {
    path: "/:lang(us|ru|en|he|ka|mne|zh|pt|tr|uz|cs|uk|de|ar)?/:token/:projectToken?/pdf/:id",
    name: "Pdf",
    component: Pdf,
  },
  {
    path: "/:lang(us|ru|en|he|ka|mne|zh|pt|tr|uz|cs|uk|de|ar)?/:token/:projectToken?/instructions/",
    name: "Instrutions",
    component: Instructions,
  },
  {
    path: "/:lang(us|ru|en|he|ka|mne|zh|pt|tr|uz|cs|uk|de|ar)?/:token/:projectToken?",
    name: "Main",
    component: Main,
    children: [
      // REDIRECTS
      {
        path: "interactive",
        beforeEnter: (to, from, next) => {
          next({
            name: "Main",
            params: {
              ...to.params,
            },
            query: to.query,
          });
        },
      },
      {
        path: "premises/:instanceId",
        beforeEnter: (to, from, next) => {
          next({
            name: "Premises-details-sidebar",
            params: {
              ...to.params,
              sidebarContext: "instance",
              sidebarSubcontext: to.params.instanceId,
            },
            query: to.query,
          });
        },
      },
      {
        path: "chess/:instanceId",
        beforeEnter: (to, from, next) => {
          next({
            name: "Chess-details-sidebar",
            params: {
              ...to.params,
              sidebarContext: "instance",
              sidebarSubcontext: to.params.instanceId,
            },
            query: to.query,
          });
        },
      },

      {
        path: "chess/storey-sidebar/:storeyId",
        beforeEnter: (to, from, next) => {
          next({
            name: "Chess-group-sidebar",
            params: {
              ...to.params,
              sidebarContext: to.params.storeyId,
            },
            query: to.query,
          });
        },
      },
      {
        path: "chess/storey-sidebar/:storeyId/instance/:instanceId",
        beforeEnter: (to, from, next) => {
          next({
            name: "Chess-details-sidebar",
            params: {
              ...to.params,
              sidebarContext: "instance",
              sidebarSubcontext: to.params.instanceId,
            },
            query: to.query,
          });
        },
      },

      {
        path: "chess/estate/:estateId/storey-sidebar/:storeyId",
        beforeEnter: (to, from, next) => {
          next({
            name: "ChessWithEstate-group-sidebar",
            params: {
              ...to.params,
              sidebarContext: to.params.storeyId,
            },
            query: to.query,
          });
        },
      },
      {
        path: "chess/estate/:estateId/storey-sidebar/:storeyId/instance/:instanceId",
        beforeEnter: (to, from, next) => {
          next({
            name: "ChessWithEstate-details-sidebar",
            params: {
              ...to.params,
              sidebarContext: "instance",
              sidebarSubcontext: to.params.instanceId,
            },
            query: to.query,
          });
        },
      },
      {
        path: "chess/estate/:estateId/instance/:instanceId",
        beforeEnter: (to, from, next) => {
          next({
            name: "ChessWithEstate-details-sidebar",
            params: {
              ...to.params,
              sidebarContext: "instance",
              sidebarSubcontext: to.params.instanceId,
            },
            query: to.query,
          });
        },
      },
      {
        path: "chessPlus",
        beforeEnter: (to, from, next) => {
          next({
            name: "ChessPlus",
            params: {
              ...to.params,
            },
            query: to.query,
          });
        },
      },
      {
        path: "chessPlus/storey-sidebar/:storeyId",
        beforeEnter: (to, from, next) => {
          next({
            name: "ChessPlus-group-sidebar",
            params: {
              ...to.params,
              sidebarContext: to.params.storeyId,
            },
            query: to.query,
          });
        },
      },

      {
        path: "chessPlus/storey-sidebar/:storeyId/instance/:instanceId",
        beforeEnter: (to, from, next) => {
          next({
            name: "ChessPlus-details-sidebar",
            params: {
              ...to.params,
              sidebarContext: "instance",
              sidebarSubcontext: to.params.instanceId,
            },
            query: to.query,
          });
        },
      },
      {
        path: "chessPlus/:instanceId",
        beforeEnter: (to, from, next) => {
          next({
            name: "ChessPlus-details-sidebar",
            params: {
              ...to.params,
              sidebarContext: "instance",
              sidebarSubcontext: to.params.instanceId,
            },
            query: to.query,
          });
        },
      },
      {
        path: "chessPlus/estate/:estateId",
        beforeEnter: (to, from, next) => {
          next({
            name: "ChessPlusWithEstate",
            params: {
              ...to.params,
            },
            query: to.query,
          });
        },
      },

      {
        path: "chessPlus/estate/:estateId/instance/:instanceId",
        beforeEnter: (to, from, next) => {
          next({
            name: "ChessPlus-details-sidebar",
            params: {
              ...to.params,
              sidebarContext: "instance",
              sidebarSubcontext: to.params.instanceId,
            },
            query: to.query,
          });
        },
      },
      {
        path: "chessPlus/estate/:estateId/storey-sidebar/:storeyId",
        beforeEnter: (to, from, next) => {
          next({
            name: "ChessPlus-group-sidebar",
            params: {
              ...to.params,
              sidebarContext: to.params.storeyId,
            },
            query: to.query,
          });
        },
      },
      {
        path: "chessPlus/estate/:estateId/storey-sidebar/:storeyId/instance/:instanceId",
        beforeEnter: (to, from, next) => {
          next({
            name: "ChessPlus-details-sidebar",
            params: {
              ...to.params,
              sidebarContext: "instance",
              sidebarSubcontext: to.params.instanceId,
            },
            query: to.query,
          });
        },
      },

      // ROUTES

      {
        path: "projects/",
        name: "Projects",
        component: PageProjects,
        meta: {
          pageTitle: "Projects",
          breadcrumb: [
            {
              text: "Projects",
              to: { name: "Projects" },
              active: true,
            },
          ],
        },
      },
      {
        path: "info/",
        name: "Info",
        component: PageInfo,
        meta: {
          pageTitle: "Info",
          breadcrumb: [
            {
              text: "Info",
              to: { name: "Info" },
              active: true,
            },
          ],
        },
      },
      {
        path: "panorama/",
        name: "Panorama",
        component: PagePanorama,
        meta: {
          pageTitle: "Panorama",
          breadcrumb: [
            {
              text: "Panorama",
              to: { name: "Panorama" },
              active: true,
            },
          ],
        },
      },
      {
        path: "interactive/:interactiveId/frame/:frameId/:storeyId?",
        name: "Interactive",
        component: PageInteractive,
        meta: {
          pageTitle: "Interactive",
          handleEnterSidebarClassImmediately: false,
          handleLeaveSidebarClassImmediately: true,
          breadcrumb: [
            {
              text: "Interactive",
              to: { name: "Interactive", params: { reset: true } },
              active: true,
            },
          ],
        },
      },
      {
        path: "premises",
        name: "Premises",
        component: PagePremises,
        meta: {
          pageTitle: "Premises",
          handleEnterSidebarClassImmediately: true,
          handleLeaveSidebarClassImmediately: true,

          breadcrumb: [
            {
              text: "Premises",
              to: { name: "Premises" },
              active: true,
            },
          ],
        },
      },
      {
        path: "chess/",
        name: "Chess",
        component: PageChess,
        meta: {
          pageTitle: "Chess",
          handleEnterSidebarClassImmediately: false,
          handleLeaveSidebarClassImmediately: true,
          breadcrumb: [
            {
              text: "Chess",
              to: { name: "Chess" },
              active: true,
            },
          ],
        },
      },
      {
        path: "chess/estate/:estateId",
        name: "ChessWithEstate",
        component: PageChess,
        meta: {
          withEstate: true,
          pageTitle: "Chess",
          handleEnterSidebarClassImmediately: false,
          handleLeaveSidebarClassImmediately: true,
          breadcrumb: [
            {
              text: "Chess",
              to: { name: "Chess", params: { reset: true } },
            },
            {
              // text: 'Estate',
              to: { name: "ChessWithEstate" },
              active: true,
              type: "estateId",
            },
          ],
        },
      },

      {
        path: "chess-plus",
        name: "ChessPlus",
        component: PageChess,
        meta: {
          withEstate: true,
          pageTitle: "ChessPlus",
          handleEnterSidebarClassImmediately: false,
          handleLeaveSidebarClassImmediately: true,
          breadcrumb: [
            {
              text: "Chess Plus",
              to: { name: "Chess", params: { reset: true } },
            },
            {
              text: "Estate",
              type: "estateId",
              to: { name: "ChessPlusWithEstate" },
              active: true,
            },
          ],
        },
      },
      {
        path: "chess-plus/estate/:estateId",
        name: "ChessPlusWithEstate",
        component: PageChess,
        meta: {
          withEstate: true,
          pageTitle: "ChessPlus",
          handleEnterSidebarClassImmediately: false,
          handleLeaveSidebarClassImmediately: true,
          breadcrumb: [
            {
              text: "Chess Plus",
              to: { name: "Chess", params: { reset: true } },
            },
            {
              text: "Estate",
              type: "estateId",
              to: { name: "ChessPlusWithEstate" },
              active: true,
            },
          ],
        },
      },
      // {
      //   path: "info",
      //   name: "Info",
      //   component: PageInfo,
      //   meta: {
      //     pageTitle: "Info",
      //     breadcrumb: [
      //       {
      //         text: "Info",
      //         to: { name: "Info" },
      //         active: true,
      //       },
      //     ],
      //   },
      // },
      {
        path: "favorites",
        name: "Favorites",
        component: Favorites,
        meta: {
          pageTitle: "Favorites",
          handleEnterSidebarClassImmediately: true,
          handleLeaveSidebarClassImmediately: true,
          breadcrumb: [
            {
              text: "Favorites",
              to: { name: "Favorites" },
              active: true,
            },
          ],
        },
      },

      // { path: "*", component: PageNotFound }
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const sidebarTypes = ["details", "info", "preview", "group"]; // Типы сайдбаров

// Функция для добавления вложенных маршрутов сайдбаров
function addSidebarRoutes() {
  const allRoutes = router.getRoutes();

  allRoutes.forEach((route) => {
    if (
      route.name === undefined ||
      route.name === "error-404" ||
      route.name === "Pdf" ||
      route.name === "Projects" ||
      route.name === "Info" ||
      route.name === "Panorama" ||
      route.name === "Instrutions" ||
      route.name === "Main"
    ) {
      return;
    }
    sidebarTypes.forEach((sidebarType) => {
      router.addRoute(route.name, {
        path: `${route.path}/${sidebarType}/:sidebarContext?/:sidebarSubcontext?`,
        name: `${route.name}-${sidebarType}-sidebar`,
        component: route.component,
        meta: {
          ...route.meta,
          isSidebar: true,
          sidebarType,
        },
        props: {
          ...route.props,
        },
      });
    });
  });
}
// Вызываем функцию для добавления маршрутов после инициализации маршрутизатора
addSidebarRoutes();

router.afterEach((to, from) => {
  const ref = store.state.ref;
  if (ref !== null) {
    window.parent.postMessage(
      {
        type: "changeRPTModulePage",
        data: to.fullPath,
      },
      ref
    );
  }
});

// // Обработка сохранения сайдбаров при переключении маршрутов
router.beforeEach((to, from, next) => {
  const lastSidebar = store.getters.lastSidebar;
  if (lastSidebar == null) {
    next();
    return;
  }
  const matchedTo = to.matched;
  const matchedFrom = from.matched;
  const isComponentChanged = !matchedTo.some((toRoute, index) => {
    const fromRoute = matchedFrom[index];
    return (
      fromRoute &&
      fromRoute.components === toRoute.components &&
      toRoute.name !== "Main"
    );
  });
  if (!isComponentChanged) {
    if (from.meta.isSidebar === true && to.meta.isSidebar !== true) {
      store.dispatch("CLOSE_NOT_PERSIST_SIDEBARS");
      store.dispatch("CLOSE_SIDEBAR_MODAL");
    }
    next();
    return;
  }
  store.dispatch("CLOSE_NOT_PERSIST_SIDEBARS");
  store.dispatch("CLOSE_SIDEBAR_MODAL");

  next();
});

export default router;
