import Vue from "vue";
import App from "./App.vue";
import VueHead from "vue-head";
import router from "./router";
import store from "./store";
// import "./assets/fonts/font/stylesheet.css";
// import "./assets/styles/style.scss";
import VueI18n from "vue-i18n";
import i18n from "@/libs/i18n";
import "bootstrap";
import VueYandexMetrika from "vue-yandex-metrika";
import LucideIcon from "@/components/helpers/LucideIcon.vue";

import LazyLoadDirective from "@/libs/directives/LazyLoadDirective";
import langMixin from "@/router/langMixin";

Vue.component(LucideIcon.name, LucideIcon);
Vue.use(VueI18n);
Vue.use(VueHead);
Vue.mixin(langMixin);
Vue.config.productionTip = false;

Vue.directive("lazyload", LazyLoadDirective);

Vue.prototype.WhitelabelConfig = [
  {
    domain: "rpt.realting.com",
    privacy_url: "https://realtyprotech.com/policy/ru",
  },
  {
    domain: "user.realt.co.il",
    privacy_url: "https://www.realt.co.il/terms",
  },
];

Vue.prototype.$changeTheme = function (startTheme, saveTheme = true) {
  const metaThemeColor = document.querySelector("meta[name=theme-color]");
  let theme;

  if (startTheme === "device") {
    theme = window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light";
  } else {
    theme = startTheme;
  }

  if (theme === "dark") {
    document.documentElement.classList.add("dark-theme");
    metaThemeColor.content = "#000";
  } else {
    document.documentElement.classList.remove("dark-theme");
    metaThemeColor.content = "#fff";
  }
  if (saveTheme) {
    try {
      localStorage.setItem("theme", startTheme);
    } catch (e) {
      // localStorage not aviliable
    }
  }
};

let rules = Vue.prototype.WhitelabelConfig.find(
  (i) => i.domain == document.domain
);
rules
  ? (Vue.prototype.Whitelabel = rules)
  : (Vue.prototype.Whitelabel = Vue.prototype.WhitelabelConfig[0]);

window.eventBus = new Vue();

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");
