export default {
    state: () => ({
        filter_visible: false,
        filters_count: 0,
        filter: [],
        only_free: false,
        only_free_used: false,
        only_free_default: false,
    }),
    getters: {
        isFilter(state) {
            return state.filter_visible;
        },
        filters_count(state) {
            return state.filters_count;
        },
        rpt_filter(state) {
            return state.filter;
        },

        only_free(state) {
            return state.only_free;
        },
        only_free_used(state) {
            return state.only_free_used;
        },
        only_free_default(state) {
            return state.only_free_default;
        },

    },
    mutations: {
        SET_FILTER_VISIBLE(state, data) {
            state.filter_visible = data;
        },
        SET_FILTERS_COUNT(state, count) {
            state.filters_count = count;
        },
        SET_FILTER(state, data) {
            state.filter = data;
        },
        SET_ONLY_FREE(state, data) {
            state.only_free = data;
        },

    },
    actions: {
        CLOSE_FILTER(ctx) {
            ctx.commit("SET_FILTER_VISIBLE", false);
        },
        TOGGLE_FILTER(ctx) {
            if (ctx.state.filter_visible) {
                ctx.commit("SET_FILTER_VISIBLE", false);
            } else {
                ctx.commit("SET_FILTER_VISIBLE", true);
            }
        },
        SAVE_FILTERS_COUNT(ctx, count) {
            ctx.commit("SET_FILTERS_COUNT", count);
        },
        UPDATE_FILTER(ctx, data) {
            if (data != null) {
                ctx.commit("SET_FILTER", data);
            } else {
                let ids = ctx.rootGetters.instances.map((instance) => instance.id);
                ctx.commit("SET_FILTER", ids);
            }
        },
        TOGGLE_ONLY_FREE(ctx,val = true) {
            ctx.state.only_free_used = val
            if (ctx.state.only_free) {
                ctx.commit("SET_ONLY_FREE", false);
            } else {
                ctx.commit("SET_ONLY_FREE", true);
            }
        },
    },

}