<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
const appHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty("--app-height", `${window.innerHeight}px`);
};
window.addEventListener("resize", appHeight);
appHeight();
export default {
  name: "App",

  created() {
    let currentTheme;
    try {
      currentTheme = localStorage.getItem("theme");
    } catch (e) {
      currentTheme = "light";
    }
    if (!currentTheme) {
      currentTheme = "light";
    }
    this.$changeTheme(currentTheme, false);
  },
};
</script>

<style lang="scss">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */

  // color: #2c3e50;
}
</style>
