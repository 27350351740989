import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);
function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
      messages[locale].langs = {
        "lang-ru": "Русский",
        "lang-en": "English",
        "lang-he": "עברית",
        "lang-mne": "Черногорский",
        "lang-zh": "中国人",
        "lang-pt": "Português",
        "lang-ka": "ქართული",
        "lang-tr": "Türkçe",
        "lang-us": "English (US)",
        "lang-uz": "Оʻzbek tili",
        "lang-cs": "Čeština",
        "lang-uk": "Українська",
        "lang-de": "Deutsche",
        "lang-ar": "عرب"
      };

    }
  });
  return messages;
}

const numberFormats = {
  RUB:{
    currency: {
      style: "currency",
      currency: "RUB",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0, 
      maximumFractionDigits: 2,
    },

    // compactCurrency: {
    //   style: "currency",
    //   currency: "RUB",
    //   currencyDisplay: "symbol",
    //   notation: "compact",
    //   minimumFractionDigits: 0, 
    //   maximumFractionDigits: 2,
    // },
  },
  BYN:{
    currency: {
      style: "currency",
      currency: "BYN",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0, 
      maximumFractionDigits: 2,
    },
  },
  USD:{
    currency: {
      style: "currency",
      currency: "USD",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0, 
      maximumFractionDigits: 2,
    },
  },
  THB:{
    currency: {
      style: "currency",
      currency: "THB",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0, 
      maximumFractionDigits: 2,
    },
  },
  CZK:{
    currency: {
      style: "currency",
      currency: "CZK",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0, 
      maximumFractionDigits: 2,
    },
  },
  GBR:{
    currency: {
      style: "currency",
      currency: "GBP",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0, 
      maximumFractionDigits: 2,
    },
  },
  EUR:{
    currency: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0, 
      maximumFractionDigits: 2,
    },
  },
  AED:{
    currency: {
      style: "currency",
      currency: "AED",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0, 
      maximumFractionDigits: 2,
    },
  },
  ILS:{
    currency: {
      style: "currency",
      currency: "ILS",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0, 
      maximumFractionDigits: 2,
    },

    // compactCurrency: {
    //   style: "currency",
    //   currency: "ILS",
    //   currencyDisplay: "symbol",
    //   notation: "compact",
    //   minimumFractionDigits: 0, 
    //   maximumFractionDigits: 2,
    // },
  },
  helpers:{
    number: {
      style: "decimal",
      minimumFractionDigits: 0, 
      maximumFractionDigits: 2,
    },
  },
  // ru: {
  //   currency: {
  //     style: "currency",
  //     currency: "RUB",
  //     currencyDisplay: "symbol",
  //     minimumFractionDigits: 0, 
  //     maximumFractionDigits: 2,
  //   },
  //
  //   compactCurrency: {
  //     style: "currency",
  //     currency: "RUB",
  //     currencyDisplay: "symbol",
  //     notation: "compact",
  //     minimumFractionDigits: 0, 
  //     maximumFractionDigits: 2,
  //   },
  //   number: {
  //     style: "decimal",
  //     minimumFractionDigits: 0, 
  //     maximumFractionDigits: 2,
  //   },
  // },
  // he: {
  //   currency: {
  //     style: "currency",
  //     currency: "ILS",
  //     currencyDisplay: "symbol",
  //     minimumFractionDigits: 2, 
  //     maximumFractionDigits: 2,
  //   },
  //
  //   compactCurrency: {
  //     style: "currency",
  //     currency: "ILS",
  //     currencyDisplay: "symbol",
  //     notation: "compact",
  //     minimumFractionDigits: 2, 
  //     maximumFractionDigits: 2,
  //   },
  //   number: {
  //     style: "decimal",
  //     minimumFractionDigits: 2, 
  //     maximumFractionDigits: 2,
  //   },
  // },
};

export default new VueI18n({
  numberFormats,
  locale: "ru",
  fallbackLocale: "en",
  messages: loadLocaleMessages(),
});


    // "langs": {
    //     "lang-ru": "Русский",
    //     "lang-en": "English",
    //     "lang-he": "עברית",
    //     "lang-mne": "Черногорский",
    //     "lang-zh": "中国人",
    //     "lang-pt": "Português",
    //     "lang-ka": "ქართული",
    //     "lang-tr": "Türkçe",
    //     "lang-us": "English (US)"
    // },
