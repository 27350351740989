<script>
import * as icons from "lucide-vue";

export default {
  name: "LucideIcon",
  functional: true,
  props: {
    icon: {
      required: true,
      type: [String, Object],
    },
    size: {
      type: [String, Number],
      default: 14,
    },
    badge: {
      type: [String, Object, Number],
      default: null,
    },
    badgeClasses: {
      type: [String, Object, Array],
      default: "badge-primary",
    },
  },
  render(h, { props, data }) {
    // Create Feather Icon

    if (data.props && typeof data.props.size !== "number") {
      data.props.size = Number(data.props.size);
    }

    const svg = h(icons[props.icon], {
      props: { size: Number(props.size) },
      ...data,
    });

    // If no badge is provided => Render just SVG
    if (!props.badge) return svg;

    // Badge
    const badgeEl = h(
      "span",
      { staticClass: "badge badge-up badge-pill", class: props.badgeClasses },
      [props.badge]
    );

    // Return span with children
    return h("span", { staticClass: "lucide-icon position-relative" }, [
      svg,
      badgeEl,
    ]);
  },
};
</script>

<style lang="scss">
svg.lucide {
  outline: none;
}
</style>
